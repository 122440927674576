import { FireModel } from './fire-model.module';

export class Contacts extends FireModel {
  static STATUS_CREATED = 1;
  static STATUS_ELIMINATED = 2;

  _id: string;
  name: string;
  headers: string[];
  lists: string[];
  usedCampaigns: string[];
  date: string;
  status: number;
  totalNumbers: number;

  constructor(data = null) {
    super('contacts', data);
    if (!this.date) {
      this.date = Contacts.getCurrentDate();
    }
  }

  async getExistContacts(name: string) {
    const path = this.getPath();
    const collectionRef = FireModel.db.firestore.collection(path);
    const querySnapshot = await collectionRef.where('name', '==', name).get();
    if (!querySnapshot.empty) {
      // Obtener la referencia al documento encontrado en la colección principal
      const doc = querySnapshot.docs[0];
      const data = new (this.constructor as any)().fromJSON(doc.data());
      data._id = doc.id;
      const dataSubCollectionRef = doc.ref.collection('data');
      const dataQuerySnapshot = await dataSubCollectionRef.get();
      const dataDocuments = dataQuerySnapshot.docs.map(dataDoc => {
        return {
          id: dataDoc.id,
          values: dataDoc.data().info,
        };
      });
      data.data = dataDocuments;
  
      return data;
    }
    return null;
  }

  static fromJSON(data: any) {
    const contact = new Contacts();
    Object.assign(contact, data);
    return contact;
  }

  async getDataContacts() {
    const path = this.getPath();
    const snapshot = await FireModel.db.firestore.collection(path).get();
    const data = snapshot.docs.map((doc) => {
      const ContactClass = this.constructor as typeof Contacts;
      return ContactClass.fromJSON(doc.data());
    });
    return data;
  }

  async pushCampingUsedCampaings(name: string, campaign_id: string) {
    const path = this.getPath();
    const querySnapshot = await FireModel.db.firestore
      .collection(path)
      .where('name', '==', name)
      .get();

    if (!querySnapshot.empty) {
      const docRef = querySnapshot.docs[0].ref;
      const docSnapshot = await docRef.get();
      const usedCampaigns = docSnapshot.get('usedCampaigns') || [];
      usedCampaigns.push(campaign_id);
      await docRef.update({ usedCampaigns });
      console.log(`Se agregó ${campaign_id} a usedCampaigns correctamente.`);
    }
  }

  async createSubCollection(parentId: string, listArray: string[][]) {
    const parentRef = FireModel.db.firestore
      .collection(this.getPath())
      .doc(parentId);
    const dataSubCollectionRef = parentRef.collection('data');
    for (let i = 0; i < listArray.length; i++) {
      const transformedDataString = JSON.stringify(listArray[i]);
      await dataSubCollectionRef
        .doc(`${i}`)
        .set({ info: transformedDataString });
    }
  }

  async deleteGroup(name: string) {
    const existingContact = await this.getExistContacts(name);
    if (existingContact) {
      const path = this.getPath();
      const docRef = FireModel.db.firestore.collection(path).doc(existingContact._id);
      await docRef.update({ status: Contacts.STATUS_ELIMINATED });
    } 
  }
}
