/* eslint-disable prefer-arrow/prefer-arrow-functions */
import Swal from 'sweetalert2';
export function alertMessageWarning(title: string, message: string) {
  Swal.fire({
    title,
    html: message,
    icon: 'warning',
    showConfirmButton: true,
    confirmButtonText: 'Cerrar',
    confirmButtonColor: '#00897b',
  });
  const swalContainer = document.querySelector(
    'div:where(.swal2-container)'
  ) as HTMLElement;
  if (swalContainer) {
    swalContainer.style.position = 'static'; // Comentado para eliminar la propiedad
    swalContainer.style.padding = '17.625em'; // Comentado para eliminar la propiedad
  }
}

export function alertMessageSuccess(title: string, message: string) {
  return new Promise((resolve, reject) => {
    Swal.fire({
      title,
      html: message,
      icon: 'success',
      showConfirmButton: true,
      confirmButtonText: 'cerrar',
      confirmButtonColor: '#00897b',
    }).then((res) => {
      if (res.isConfirmed) {
        resolve(true);
      } else {
        resolve(false);
      }
    }).catch(reject);

    const swalContainer = document.querySelector('div:where(.swal2-container)') as HTMLElement;
    if (swalContainer) {
      swalContainer.style.position = 'static';
      swalContainer.style.padding = '17.625em';
    }
  });
}

export function alertMessageWarningTypeTwo(title: string, message: string, up: boolean) {
  return new Promise((resolve, reject) => {
    Swal.fire({
      title,
      html: message,
      icon: 'warning',
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonText: 'Continuar',
      cancelButtonText: 'Cancelar',
      confirmButtonColor: '#00897b',
      cancelButtonColor: '#FF5733',
    }).then((res) => {
      if (res.isConfirmed) {
        resolve(true);
      } else {
        resolve(false);
      }
    }).catch(reject);

    if(up == true) {
      const styleElement = document.createElement('style');
      styleElement.textContent = `
        div:where(.swal2-container) div:where(.swal2-popup) {
          top: -70px;
        }
      `;
      Swal.getHtmlContainer().appendChild(styleElement);
    }

    const swalContainer = document.querySelector('div:where(.swal2-container)') as HTMLElement;
    if (swalContainer) {
      swalContainer.style.position = 'static';
      swalContainer.style.padding = '17.625em';
    }
  });
}


export function alertMessageWithInput( title: string, inputLabel: string): Promise<{ value: string | null; isConfirmed: boolean }> {
  return new Promise((resolve, reject) => {
    Swal.fire({
      title,
      input: 'text',
      inputLabel,
      icon: 'success',
      showCancelButton: true,
      confirmButtonText: 'Continuar',
      cancelButtonText: 'Cancelar',
      confirmButtonColor: '#00897b',
    }).then((result) => {
      resolve({
        value: result.value,
        isConfirmed: result.isConfirmed,
      });
    }).catch(reject);

    const swalContainer = document.querySelector('div:where(.swal2-container)') as HTMLElement;
    if (swalContainer) {
      swalContainer.style.position = 'static';
      swalContainer.style.padding = '17.625em';
    }
  });
}

